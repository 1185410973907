import React from "react"
import { useStaticQuery, Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

// Three column single row grid, allows us to position login and header button
const Wrapper = styled.div`
display: grid;
height: 44px;
margin-bottom: 24px;
grid-template-columns:  1fr;
`;

const List = styled.ul`
margin: 0;
list-style-type: none;
li:before {
  content: "-";
}
`;

const Category = styled.span`
  text-transform: none;
`;

const PostTitle = styled.span`
  text-transform: lowercase;
`;

// https://www.gatsbyjs.org/docs/use-static-query/
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="dro.pe" />
      <Wrapper>
        <div>
          <StaticQuery
            query={
              graphql`
              {
                allMarkdownRemark(
                  sort: { order: DESC, fields: [frontmatter___date] }
                  limit: 1000
                ) {
                  edges {
                    node {
                      frontmatter {
                        path,
                        title,
                        date,
                        category
                      }
                    }
                  }
                }
              }          
              `}
            render={data => (
              data.allMarkdownRemark.edges.map((edge) => {
                const { path, title, date, category } = edge.node.frontmatter;
                return <div> [<Category>{category}</Category>] <Link to={path}> <PostTitle>{`${title}`}</PostTitle></Link> </div>
              })
            )}
          />
        </div>
        <div>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default IndexPage